import { IAsRatio, IPolygon } from 'components/canvasimage/canvasLayer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  DefectHover,
  IInspectionDetail,
  IforeignMat,
  Iimage,
  imageDefects
} from 'pages/inspection/inspectionDetails';

dayjs.extend(utc);

export const IMAGE_CONTAINER_WIDTH_GAP = 50;
export const IMAGE_CONTAINER_HEIGHT_GAP = 125;

const localStorageKey = 'greyscaleaiV3-';

export const getFiltersObj = (iFrameObj: HTMLIFrameElement | null) => {
  let filters;
  if (iFrameObj) filters = getFiltersFromUrl(iFrameObj.src);
  return filters;
};

const getFiltersFromUrl = (url: string) => {
  const filters: Record<string, string> = {};
  //   console.log("url , ", url);
  const parsedUrl = new URL(url);
  const searchParamArray = parsedUrl.search.slice(1).split('&');
  searchParamArray.forEach((searchParam) => {
    const keyValPair = searchParam.split('=');
    filters[keyValPair[0]] = keyValPair[1];
  });
  return filters;
};

export const addFiltersInUrl = ({
  url,
  localStorageFilters,
  filtersExceptionList,
  searchParams,
  storageKey,
  session_id
}: TaddFiltersInUrl) => {
  let newUrl = url;
  let search = localStorageFilters;
  if (searchParams && [...searchParams].length > 0) {
    const urlSearch = new URLSearchParams(search);
    searchParams.forEach((value, key) => {
      urlSearch.set(key, value);
    });
    urlSearch.set('kiosk', 'true');
    if (session_id) urlSearch.set('var-SessionId', session_id);
    search = `?${urlSearch.toString()}`;
    if (search !== localStorageFilters) {
      if (storageKey) localStorage.setItem(storageKey, search);
    }
  }
  search = removeExceptionFiltersFromUrl(search, filtersExceptionList);
  if (search) {
    newUrl = `${newUrl}${search}`;
  }
  return newUrl;
};

export const getGACustomDimensions = (search: string, exceptions: string[]) => {
  const filters: Record<string, string> = {};
  const advancedFilters: Record<string, string> = {};
  const parsedUrl = new URL(search);
  const params = new URLSearchParams(parsedUrl.search);
  for (const [key, value] of params.entries()) {
    const normalizedKey = key.replace(/^(var-|Advanced)/, '');
    if (!exceptions.includes(key) && value) {
      (key.includes('Advanced') ? advancedFilters : filters)[normalizedKey] = value;
    }
  }
  return { filters, advancedFilters };
};

export const removeExceptionFiltersFromUrl = (
  url: string | undefined = '',
  filterExceptionList: string[]
): string => {
  if (!url) return '';

  let updatedUrl = filterExceptionList.reduce((accum, filtersToSkip) => {
    const regExp = new RegExp(`[&?]${filtersToSkip}(=[^&]*)?`, 'g');
    return accum.replace(regExp, '');
  }, url);

  updatedUrl = updatedUrl.replace(/^[&?]/, '');

  if (updatedUrl.endsWith('?')) {
    updatedUrl = updatedUrl.slice(0, -1);
  }

  if (url.startsWith('?') && updatedUrl) {
    updatedUrl = '?' + updatedUrl;
  }

  return updatedUrl;
};

type TaddFiltersInUrl = {
  url: string;
  iFrameLocation?: any;
  localStorageFilters?: string;
  filtersExceptionList: string[];
  searchParams?: URLSearchParams;
  storageKey?: string;
  session_id: string | undefined;
};

export const getEmailId = (token: any) => {
  if (token?.payload?.email) {
    return token.payload.email;
  } else if (token?.payload['identities']?.[0]?.userId) {
    return token.payload['identities']?.[0]?.userId;
  }
  return '';
};

export const getKeyForStorage = (authDetails: any) => {
  let email = '';
  if (authDetails && authDetails.userSession) {
    email = getEmailId(authDetails.userSession.getIdToken());
  }
  return `${localStorageKey}${email}`;
};

export const getProfileName = (userDetails: Partial<IUserDetail>) => {
  const { given_name, family_name, email } = userDetails;
  if (given_name) {
    if (family_name) return `${given_name} ${family_name.substr(0, 1)}`;
    return `${given_name}`;
  } else if (email) {
    return `${email}`;
  }
};

export const calculateTimeDifference = (timestamp: string): number => {
  const timestampDateInstanse = new Date(timestamp) as any;
  const currentTime = new Date() as any;

  const minuteDifference = Math.floor((currentTime - timestampDateInstanse) / 60000);
  return minuteDifference;
};

function appendZero(month: number) {
  return month > 9 ? month : `0${month}`;
}

export function getTimeInLocalFormat(timestamp: string) {
  const tsd = new Date(timestamp);
  if (typeof tsd == 'object') {
    return `${tsd.getFullYear()}-${appendZero(tsd.getMonth() + 1)}-${appendZero(
      tsd.getDate()
    )} ${appendZero(tsd.getHours())}:${appendZero(tsd.getMinutes())}:${appendZero(
      tsd.getSeconds()
    )}.${tsd.getMilliseconds()} `;
  }
}

export function myThrottle<T extends (...args: any) => any>(fnc: T, timeout: number) {
  let clearTimeoutValue: NodeJS.Timeout;
  return function (this: any, ...args: any[]) {
    if (clearTimeoutValue) return;
    clearTimeoutValue = setTimeout(() => {
      clearTimeout(clearTimeoutValue);
      fnc.apply(this, args as any);
    }, timeout);
  };
}
export function getproductDiff(
  products: { product_code: string; inspections: number }[],
  configs: string[]
): { product_code: string; inspections: number }[] {
  const setB = new Set(configs);
  return products.filter((item) => !setB.has(item.product_code));
}

export function calculateAspectRatio(
  containerDiv: HTMLDivElement | null,
  image: HTMLImageElement | undefined
) {
  let aspectRWidth = 1;
  let aspectRHeight = 1;
  let actualImageAspR = 1;
  let canvasWidth = 1;
  let canvasHeight = 1;
  if (containerDiv && image && image.naturalWidth !== 0) {
    actualImageAspR = +(image.naturalWidth / image.naturalHeight).toFixed(5);
    // here assuming container height value will be lower as compared to width
    canvasHeight = +(containerDiv.clientHeight - IMAGE_CONTAINER_HEIGHT_GAP).toFixed(5);
    canvasWidth = +(canvasHeight * actualImageAspR).toFixed(5);

    if (canvasWidth > containerDiv.clientWidth) {
      canvasWidth = containerDiv.clientWidth - IMAGE_CONTAINER_WIDTH_GAP;
      canvasHeight = canvasWidth / actualImageAspR;
    }
    aspectRWidth = +(canvasWidth / image.naturalWidth).toFixed(5);
    aspectRHeight = +(canvasHeight / image.naturalHeight).toFixed(5);
  }
  return { aspectRWidth, aspectRHeight, actualImageAspR, canvasWidth, canvasHeight };
}

export const filterDefectsForImage = (forMat: IforeignMat[] = [], currentImage: Iimage) => {
  const { id } = currentImage;
  return forMat.filter((fmDefects) => {
    if (!fmDefects.images) return false;
    return fmDefects.images.some((img: imageDefects) => {
      return img.image_id === id;
    });
  });
};
export const returnBoundingBoxConfig = (
  defectsForImage: IforeignMat[] = [],
  aspectRatio: Pick<IAsRatio, 'aspectRWidth' | 'aspectRHeight'>,
  actualImage: any,
  currentImage: Iimage
) => {
  aspectRatio = aspectRatio ? aspectRatio : { aspectRWidth: 1, aspectRHeight: 1 };
  const actImg: HTMLImageElement = actualImage
    ? actualImage
    : { naturalWidth: 1, naturalHeight: 1 };
  return (
    defectsForImage.reduce((accum: any, fm) => {
      const { images } = fm;
      const img = images.filter((image) => {
        return image.image_id === currentImage.id ? true : false;
      })[0];
      const polygon =
        img.polygon?.reduce((accum: any, poly) => {
          const x = poly[0] * aspectRatio.aspectRWidth * actImg?.naturalWidth;
          const y = poly[1] * aspectRatio.aspectRHeight * actImg?.naturalHeight;
          accum.push([x, y]);
          return accum;
        }, [] as any) || [];
      const rect: IPolygon = {
        cord: polygon,
        stroke: '#D82828',
        defectId: fm.id,
        strokeWidth: 2
      };
      accum.push(rect);
      return accum;
    }, []) || []
  );
};

export const processInspDetailData = (inspDetail: IInspectionDetail) => {
  const forMat = inspDetail?.defects?.reduce((accum: any, defect, index) => {
    if (Object.keys(defect).length !== 0) {
      defect.id = `defect-${index}`;
      accum.push(defect);
    }
    return accum;
  }, []);

  const fm = { defects: [] };
  fm.defects = forMat || [];
  return {
    ...inspDetail,
    ...fm
  };
};

export const classifyData = (inspDetail: IInspectionDetail) => {
  return inspDetail?.defects?.reduce((accum: Record<string, IforeignMat[]>, data) => {
    if (!accum[data.defect_type]) {
      accum[data.defect_type] = [];
    }

    accum[data.defect_type].push(data);

    return accum;
  }, {});
};

export const filterImagesOfDefect = (
  defectId: string,
  inspDetail: IInspectionDetail
): Set<string> => {
  const defect = inspDetail?.defects?.filter((defect) => defect.id === defectId);
  const images = defect.reduce((_, defect: any) => {
    return defect.images.map((img: any) => img.image_id);
  }, []);
  return new Set(images);
};

export const getImagesWithDefectId = (
  defectId: string,
  flag: TFlag,
  defectHoveredRowIndex: DefectHover,
  inspectionDetail: IInspectionDetail
): Set<string> => {
  const returnData = new Set<string>([]);
  const imageList = filterImagesOfDefect(defectId, inspectionDetail);

  if (flag === 'Click' && defectHoveredRowIndex.ClickRowIndex === defectId) {
    return returnData;
  }

  if (flag === 'Click') {
    return imageList;
  }

  if (flag === 'Leave') {
    if (defectHoveredRowIndex.ClickRowIndex) {
      const currClickedImages = filterImagesOfDefect(
        defectHoveredRowIndex.ClickRowIndex,
        inspectionDetail
      );
      return currClickedImages;
    } else {
      return returnData;
    }
  }

  if (flag === 'Hover') {
    if (defectHoveredRowIndex.ClickRowIndex) {
      const currClickedImages = filterImagesOfDefect(
        defectHoveredRowIndex.ClickRowIndex,
        inspectionDetail
      );
      currClickedImages.forEach((imageId) => imageList.add(imageId));
    }
    return imageList;
  }
  return returnData;
};

export const isImageHasDefect = (forMat: IforeignMat, imageId: string) => {
  return forMat.images.some((img) => img.image_id === imageId);
};

export const convertToLocalTimeZone = (time: string, showTimeVariance = false) =>
  `${dayjs
    .utc(time)
    .local()
    .format(`YYYY-MM-DD HH:mm:ss.SSS${showTimeVariance ? ' Z' : ''}`)}`;

export const adminRoles = ['GSAI Admin', 'Company Admin']; // All access roles
export const companyViewerRoles = ['Company Viewer', 'GSAI Viewer']; // All Access - Manage Users
export const factoryViewerRoles = ['Factory Viewer']; // Restricted to Factories

export const checkIfAdmin = (role?: string) => adminRoles.includes(role || '');

export const checkIfCompanyViewer = (role?: string) =>
  [...adminRoles, ...companyViewerRoles].includes(role || '');

interface IUserDetail {
  email: string;
  userId: string;
  given_name: string;
  family_name: string;
}
type TFlag = 'Hover' | 'Leave' | 'Click';
